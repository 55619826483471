.topics {
  border-bottom: 1px solid #dcdcdc;
  padding: 1.9rem 0;
  width: 90%;
  text-align: start;
  padding-left: 1.5rem;
  font-size: 1rem;
}

.ribbonHeight {
  height: calc(100vh - 5rem);
  overflow-y: scroll;
}
