#headerMobileButton {
  .ant-btn-primary,
  .ant-btn-primary:hover {
    border-color: black;
    color: black;
  }
  .ant-btn-primary:hover {
    background: transparent;
  }
}

.mobileTopics {
  border-bottom: 1px solid #dcdcdc;
  padding: 1.25rem 0;
  width: 80%;
  text-align: center;
}
