@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Rubik", sans-serif;
}

#activeShadow {
  background: #d9d9d9;
}
